<template>
  <div class="container">
    <el-card>
      <div slot="header">
        <h3>资金入境</h3>
      </div>
      <div>
        <el-form :inline="true" label-width="100px">
          <el-form-item label="交易流水号：">
            <el-input
              v-model="searchForm.serialNo"
              placeholder="请输入交易流水号"
            ></el-input>
          </el-form-item>
          <el-form-item label="提交时间：">
            <el-date-picker
              v-model="searchForm.applyTime"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="searchForm.status" placeholder="全部" clearable>
              <el-option
                v-for="item in statusList"
                :label="item.name"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button type="primary" @click="onUpload">上传交易明细</el-button>
          </el-form-item>
        </el-form>

        <div v-loading="loading">
          <el-table :data="tableData" stripe border style="width: 100%">
            <el-table-column prop="index" label="序号" width="50">
              <template slot-scope="scope">
                <span>
                  {{ (currentPage - 1) * pageSize + scope.$index + 1 }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="serialNo"
              label="交易流水号"
              width="180"
            ></el-table-column>
            <el-table-column prop="fileName" label="文件名" width="160px">
              <template slot-scope="scope">
                <a
                  href="javascript:void (0);"
                  @click="handleDownloadFile(scope.row)"
                >
                  {{ scope.row.fileName }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="明细金额">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.amount ? `CNH ${scope.row.amount}` : '' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="手续费">
              <template slot-scope="scope">
                <span>{{ scope.row.fee ? `CNH ${scope.row.fee}` : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="待下发金额">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.noIssueAmount
                      ? `CNH ${scope.row.noIssueAmount}`
                      : ''
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="已下发金额">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.issueAmount ? `CNH ${scope.row.issueAmount}` : ''
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="申请状态">
              <template slot-scope="scope">
                <span>
                  {{ getStatusName(scope.row.status) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-popconfirm
                  title="确定删除当前内容？"
                  @confirm="onDelete(scope.row)"
                >
                  <el-button
                    v-show="scope.row.status === 1"
                    slot="reference"
                    size="mini"
                    type="danger"
                  >
                    删除
                  </el-button>
                </el-popconfirm>

                <el-button
                  v-show="scope.row.status === 1"
                  style="margin-left: 10px"
                  size="mini"
                  type="success"
                  @click="onAction(scope.row)"
                >
                  申请结汇
                </el-button>
                <el-button
                  v-show="scope.row.status === 3"
                  style="margin-left: 10px"
                  size="mini"
                  type="primary"
                  @click="handleClickDetail(scope.row)"
                >
                  结汇记录
                </el-button>
                <el-button
                  v-show="scope.row.status === 4"
                  style="margin-left: 10px"
                  size="mini"
                  type="primary"
                  @click="handleDownloadErrorFile(scope.row)"
                >
                  下载失败详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination-container">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-card>

    <el-dialog
      title="确认发起结汇"
      :visible.sync="exchangeDialog"
      :close-on-click-modal="false"
      :before-close="exchangeBeforeClose"
      width="300px"
    >
      <div>
        <p>交易流水号：{{ exchangeDetail.serialNo }}</p>
        <p>明细金额：CNH {{ exchangeDetail.amount }}</p>
        <p>手续费金额：CNH {{ exchangeDetail.fee }}</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exchangeDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleClickExchangeDialog"
          :loading="btnLoading"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="上传交易明细"
      :visible.sync="uploadDialog"
      :close-on-click-modal="false"
      :before-close="exchangeBeforeClose"
      width="500px"
    >
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <el-upload
            ref="upload"
            :headers="uploadHeader"
            :action="`${config.baseApi}/exchange-api/founds/uploadFundsEntryFile`"
            :on-success="handleFileSuccess"
            :on-error="handleFileError"
            :data="{
              fileName: fileList.length > 0 ? fileList[0].name : '',
              companyName,
            }"
            :auto-upload="false"
            :show-file-list="false"
            :file-list="fileList"
            :on-change="handleUpdateChange"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <a :href="downloadUrl" style="margin-left: 20px">
            点击下载交易明细模版
          </a>
        </div>
        <div style="padding-left: 10px">
          {{ fileList.length > 0 ? fileList[0].name : '' }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload" :loading="btnLoading">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="错误信息下载"
      :visible.sync="errorDialog"
      :close-on-click-modal="false"
      width="400px"
    >
      <div>
        <p style="color: red; font-weight: bold; font-size: 17px">
          文件数据校验不通过
        </p>
        <span
          style="color: #2c68ff; cursor: pointer"
          @click="downloadErrorFile"
        >
          点击下载错误文件
        </span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="errorDialog = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteData,
  downloadFile,
  getCompanyId,
  getTableData,
  sendExchange,
  downloadErrorFile,
} from './api';
import config from '@/config';
import storage from '@/utils/storage';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      searchForm: {
        serialNo: '',
        applyTime: '',
        status: '',
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      statusList: [
        {
          value: 1,
          name: '新建',
        },
        {
          value: 2,
          name: '提交申请',
        },
        {
          value: 3,
          name: '申请成功',
        },
        {
          value: 4,
          name: '申请失败',
        },
      ],
      exchangeDialog: false,
      exchangeDetail: { id: '', serialNo: '', amount: '', fee: '' },
      btnLoading: false,
      uploadDialog: false,
      config,
      fileList: [],
      downloadUrl: '',
      uploadHeader: { Authorization: storage.getItem('token') },
      companyName: storage.getItem('companyName'),
      errorDialog: false,
      wbout: '',
      companyId: '',
    };
  },
  async mounted() {
    const env = process.env.NODE_ENV;

    if (['development', 'production'].includes(env)) {
      this.downloadUrl = '/template2.xlsx';
    } else {
      this.downloadUrl = '/exchange/template2.xlsx';
    }
    await this._getCompanyId();
    this.getTableData();
  },
  methods: {
    async _getCompanyId() {
      const res = await getCompanyId();
      this.companyId = res.companyId;
    },
    handleClickDetail({ noIssueAmount, id, serialNo, issueAmount, amount }) {
      this.$router.push({
        path: 'issue-details',
        query: {
          noIssueAmount,
          id,
          serialNo,
          issueAmount,
          amount,
        },
      });
    },
    async handleDownloadErrorFile(row) {
      await downloadErrorFile({ path: row.fileFailPath });
    },
    async handleDownloadFile({ fileUrl, fileName }) {
      try {
        const params = new FormData();
        params.append('fileUrl', fileUrl);
        params.append('fileName', fileName);
        await downloadFile(params);
        this.$message.success('下载成功');
      } catch (e) {
        console.log(e);
      }
    },
    handleUpdateChange(file) {
      const allowedMimeTypes = [
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      ];

      if (!allowedMimeTypes.includes(file.raw.type)) {
        this.$message.error('只能上传 xls 或者 xlsx 文件格式内容!');
        return false;
      }
      this.fileList.splice(0, 1, file);
    },
    submitUpload() {
      try {
        if (this.fileList.length <= 0) {
          this.$message.warning('请上传文件');
          return;
        }
        this.btnLoading = true;
        this.$refs.upload.submit();
      } catch (e) {
        this.btnLoading = false;
      }
    },
    downloadErrorFile() {
      saveAs(this.wbout, '错误信息.xls');
    },
    handleFileSuccess(res) {
      if (res.code === 100200) {
        this.uploadDialog = false;
        this.getTableData();
        this.fileList = [];
        this.$message.success(res.msg);
      } else if (res.code === 100500) {
        const byteCharacters = atob(res.dataSet.errorData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        this.wbout = new Blob([byteArray], {
          type: 'application/vnd.ms-excel',
        });
        this.uploadDialog = false;
        this.errorDialog = true;
        // this.$message.error(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      this.btnLoading = false;
    },
    handleFileError() {
      this.btnLoading = false;
    },
    exchangeBeforeClose(done) {
      for (const key in this.exchangeDetail) {
        this.exchangeDetail[key] = '';
      }
      this.fileList = [];
      done();
    },
    getStatusName(status) {
      return this.statusList.find(item => item.value === status)?.name;
    },
    async getTableData() {
      this.loading = true;
      try {
        const res = await getTableData({
          ...this.searchForm,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          companyId: this.companyId,
        });
        this.tableData = res?.dataSet;
        this.total = res?.pages?.total;
      } catch (e) {
        console.log(e, 'error');
      }
      this.loading = false;
    },
    onSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    onUpload() {
      this.uploadDialog = true;
      this.fileList = [];
    },
    async onDelete(row) {
      try {
        const res = await deleteData({ id: row.id });
        this.getTableData();
        this.$message.success('删除成功');
      } catch (e) {
        this.$message.error('删除失败');
      }
    },
    onAction(row) {
      for (const key in this.exchangeDetail) {
        if (row[key]) {
          this.exchangeDetail[key] = row[key];
        }
      }
      this.exchangeDialog = true;
    },
    async handleClickExchangeDialog() {
      this.btnLoading = true;
      try {
        const res = await sendExchange({ id: this.exchangeDetail.id });
        this.getTableData();
        this.$message.success('提交成功');
        this.exchangeDialog = false;
      } catch (e) {
        console.log(e);
      }
      this.btnLoading = false;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 20px;
}
.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style>
